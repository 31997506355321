export const commonConfig = {
  GOOGLE_CLOUD_BASE_URL: "https://storage.googleapis.com/smartphotoedits",
  URL: "https://www.photoeditingoutsourcing.com",
  COUNTRY_LIST: [
    "Australia",
    "Canada",
    "Netherlands",
    "New Zealand",
    "Sweden",
    "Switzerland",
    "United Kingdom",
    "United States",
    "Italy",
    "Belgium",
    "Norway",
    "France",
    "Finland",
    "Israel",
    "Ireland",
    "Singapore",
    "Denmark",
    "Germany",
  ],
  // CORS_URL: "https://server882.herokuapp.com/",
  CORS_URL: "https://cors-server-go8c.onrender.com/",
}
